// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import { TempusDominus } from '@eonasdan/tempus-dominus';
window.TempusDominus = TempusDominus;


import '../js/bootstrap_js_files'
import '../js/form_tools'
import '../js/location_filter'
import '../js/search'
import '../js/search_map'
import '../js/group_markers'
import '../js/datepicker'

Rails.start()
Turbolinks.start()
ActiveStorage.start()