document.addEventListener('turbolinks:load', function() {
  if (typeof window.runMapOnce === 'undefined') {
    window.markers = [];
    window.search_markers = [];
    window.grouped_markers = [];
    window.marker_map = {};
    var updateSearchTimeout;

    window.initSearchMap = function initSearchMap() {
      var bounds = new google.maps.LatLngBounds();
      var latLng1 = new google.maps.LatLng(60.000149, -132.140165);
      var latLng2 = new google.maps.LatLng(41.676556, -52.722832);
      bounds.extend(latLng1);
      bounds.extend(latLng2);

      window.search_map = new google.maps.Map(document.getElementById("searchMap"), {
        center: bounds.getCenter(),
        mapId: process.env.GOOGLE_MAP_ID,
        disableDefaultUI: true,
        clickableIcons: false,
      });
      window.search_map.fitBounds(bounds);
      setSearchMapControls()
    };

    $(document).on('change', '#offcanvasSearch input, #offcanvasSearch select', function() {
      var path = $('#offcanvasSearch').data('search');
      clearTimeout(updateSearchTimeout);
      updateSearchTimeout = setTimeout(function() {
        updateSearch(path);
      }, 1200);
    });

    $(document).on('click', '[data-toggle=layers]', function() {
      let layersWindow = $('#layersWindow');
      if (layersWindow.hasClass('collapsed')) {
        layersWindow.removeClass('collapsed');
      } else {
        layersWindow.addClass('collapsed');
      }
    });

    $(document).on('click', '#accordionGeoArea [data-edit]', function() {
      if (!$(this).hasClass('collapsed')) {
        var path = $(this).data('path')
        $.ajax(path, {
          method: "GET",
          dataType: 'script',
          data: {
            authenticity_token: $('[name="csrf-token"]')[0].content,
          },
        });
      }
    });

    function setSearchMapControls() {
      // Zoom in button
      document.getElementById("zoom-in").addEventListener("click", function() {
        window.search_map.setZoom(window.search_map.getZoom() + 1);
      });

      // Zoom out button
      document.getElementById("zoom-out").addEventListener("click", function() {
        window.search_map.setZoom(window.search_map.getZoom() - 1);
      });

      // Road view button
      document.getElementById("roadmap").addEventListener("click", function() {
        window.search_map.setMapTypeId('roadmap');
      });

      // Satellite view button
      document.getElementById("satellite").addEventListener("click", function() {
        window.search_map.setMapTypeId('satellite');
      });

      var zoomChangedTimeout;

      google.maps.event.addListener(window.search_map, 'zoom_changed', function() {
        clearTimeout(zoomChangedTimeout);
        zoomChangedTimeout = setTimeout(function() {
          window.groupMarkers(window.search_markers, window.search_map);
        }, 50);
      });
    }

    function updateSearch(path) {
      var query = $('#mapSearch').val()
      var clients = getCheckedNames('#offcanvasSearch [data-section="clients"]');
      $.ajax(path, {
        method: "GET",
        dataType: 'script',
        data: {
          search: {
            query: query,
            clients: JSON.stringify(clients),
          },
          authenticity_token: $('[name="csrf-token"]')[0].content,
        },
        success: function(response) {
          console.log('Search Updated')
        },
      });
    }

    function getCheckedNames(selector) {
      var checkedCheckboxes = $(`${selector} input[type="checkbox"]:checked`);
      var checkedNames = [];
      var header = $(selector).find('.heading-label')

      // Loop through each checked checkbox and get its name
      checkedCheckboxes.each(function() {
        checkedNames.push($(this).attr('name'));
      });

      if (checkedNames.length === 0 ) {
        header.addClass('text-medium-dark').removeClass('text-primary')
      } else {
        header.addClass('text-primary').removeClass('text-medium-dark')
      }

      return checkedNames;
    }
    window.runMapOnce = true;
  }
});
