document.addEventListener('turbolinks:load', function() {
  if (!window.datepickerLoaded) {

    $(document).on('focus', '.datetime', function() {
      var minDate = undefined
      var maxDate = undefined

      if ($(this).data('date-min') !== undefined) {
        var minDateAttribute = $(this).data('date-min');
        var minDate = new Date(minDateAttribute);
      }

      if ($(this).data('date-max') !== undefined) {
        var maxDateAttribute = $(this).data('date-max');
        var maxDate = new Date(maxDateAttribute);
      }
      
      new window.TempusDominus(this, {
        display: {
          icons: {
            type: 'icons',
            time: 'bi bi-clock-fill',
            date: 'bi bi-calendar-event-fill',
            up: 'bi bi-arrow-up-short',
            down: 'bi bi-arrow-down-short',
            previous: 'bi bi-chevron-left',
            next: 'bi bi-chevron-right',
            today: 'bi bi-calendar-check-fill',
            clear: 'bi bi-trash3-fill',
            close: 'bi bi-x-lg'
          },
          sideBySide: true,
          calendarWeeks: false,
          viewMode: 'calendar',
          toolbarPlacement: 'bottom',
          keepOpen: false,
          buttons: {
            today: false,
            clear: true,
            close: true
          },
          components: {
            calendar: true,
            date: true,
            month: false,
            year: false,
            decades: false,
            clock: true,
            hours: true,
            minutes: true,
            seconds: false
          }
        },
        localization: {
          format: 'yyyy-MM-dd h:mm T',
          dateFormats: {
            LTS: 'h:mm:ss T',
            LT: 'h:mm T',
            L: 'yyyy/MM/dd',
            LL: 'MMMM d, yyyy',
            LLL: 'MMMM d, yyyy h:mm T',
            LLLL: 'dddd, MMMM d, yyyy h:mm T'
          },
        },
        restrictions: {
          minDate: minDate,
          maxDate: maxDate
        },
        stepping: 15
      });
    });
    
    window.datepickerLoaded = true;
  }
});