document.addEventListener('turbolinks:load', function() {
  if ($('select[data-location=province]').length) {
    $('select[data-location=province]').on('change', function() {
      var province = $(this)
      var parent = province.closest('div[data-location=parent]');
      var city = parent.find('select[data-location=city]');
      var local = city.closest('div[data-local=parent]');
      var city_div = local.find('div[data-local=city]');
      var manual_div = local.find('div[data-local=manual]');
      manual_div.addClass('d-none');
      city_div.removeClass('d-none');
      $.ajax('/locations', {
        method: "GET",
        dataType: 'script',
        data: {
          type: 'Province',
          children: 'cities',
          id: province.val(),
          destination_tag: city.prop('id')
        }
      });
    });
  };
  
  if ($('select[data-location=city]').length) {
    $('select[data-location=city]').on('change', function() {
      var city = $(this)
      if (city.val() === 'me') {
        if (confirm('Are you sure you want to manually set the city name?')) {
          var local = city.closest('div[data-local=parent]');
          var city = local.find('div[data-local=city]');
          var manual = local.find('div[data-local=manual]');
          city.addClass('d-none');
          manual.removeClass('d-none');
        } else {
          city.val('')
        }
      };
    });
  };
});