document.addEventListener('turbolinks:load', function() {
  if (!window.formToolsLoaded) {
    $(document).on("change", "[data-action=hide-object]", function(event) {
      if ($(this).is(":checkbox")) {
        var targetValue = $(this).data("target");
        var targetObj = $(`[data-object='${targetValue}']`);
        if ($(this).is(':checked')) {
          targetObj.removeClass("d-none");
        } else {
          targetObj.addClass("d-none");
        }
      }
    });

    // Set the variable to indicate that the code has been loaded
    window.formToolsLoaded = true;
  }

  
});