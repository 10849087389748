document.addEventListener('turbolinks:load', function() {
  window.markers = [];
  window.area_labels = [];
  window.area_polys = [];
  window.area_lines = [];
  window.grouped_markers = [];
  window.marker_map = {};
  window.area_map = {};
  window.area_label_map = {};
  window.area_poly_map = {};
  window.area_line_map = {};

  window.groupMarkers = function groupMarkers(markers, map) {
    let map_bounds = map.getBounds();
    let map_sw = map_bounds.getSouthWest();
    let map_ne = map_bounds.getNorthEast();
    let distanceNS = google.maps.geometry.spherical.computeDistanceBetween(map_sw, new google.maps.LatLng(map_ne.lat(), map_sw.lng()));
    let distanceEW = google.maps.geometry.spherical.computeDistanceBetween(map_sw, new google.maps.LatLng(map_sw.lat(), map_ne.lng()));


    expired_group_markers = []
    for (var i = 0; i < window.grouped_markers.length; i++) {
      var marker = window.grouped_markers[i];
      expired_group_markers.push(marker)
    }
    var current_zoom = map.getZoom()
    var groups = [];
    
    for (var i = 0; i < markers.length; i++) {
      var marker = markers[i];
      var position = marker.position;
      var grouped = false;

      marker.map = map

      for (var j = 0; j < groups.length; j++) {
        var group = groups[j];
        var center = group.marker.position;
        var distance = google.maps.geometry.spherical.computeDistanceBetween(position, center);
        var metersPerPx = 156543.03392 * Math.cos(group.marker.position.lat * Math.PI / 180) / Math.pow(2, current_zoom)
        if (distance < (metersPerPx * 43)) { // adjust this value to set the distance threshold for grouping markers
          group.count++;
          group.markers.push(marker);
          group.marker_ids.push($(marker.element).attr('id'));
          group.listing_ids.push($(marker.element).data('id'));
          grouped = true;
          marker.map = null;
          break;
        }
      }

      if (!grouped) {
        var group = {
          marker: marker,
          count: 1,
          markers: [marker],
          marker_ids: [$(marker.element).attr('id')],
          listing_ids: [$(marker.element).data('id')]
        };
        groups.push(group);
        marker.map = map
      }
    }

    for (var i = 0; i < groups.length; i++) {
      let group = groups[i];
      let group_marker = group.marker;
      let group_markers = group.markers;
      let group_marker_ids = group.marker_ids;
      let group_listing_ids = group.listing_ids;
      let count = group.count;

      if (count > 1) {
        group_marker.map = null;
        let bounds = new google.maps.LatLngBounds();
        for (var mi = 0; mi < group_markers.length; mi++) {
          bounds.extend(group_markers[mi].position);
        }

        let gm_position = bounds.getCenter();
        if (count < 100) {
          var marker_count = count
        } else {
          var marker_count = '99+'
        }
        let content = document.createElement("div");
        content.classList.add("marker");
        content.classList.add("group");
        content.setAttribute("data-markers", group_marker_ids);
        $(content).append(`<div class='icon'>${marker_count}</div>`);
        $(content).append("<div class='icon-bg'></div>");
        let new_group_marker = new google.maps.marker.AdvancedMarkerView({
          map: map,
          content: content,
          position: gm_position,
        });

        new_group_marker.addListener("click", (event) => {
          let sw = bounds.getSouthWest();
          let ne = bounds.getNorthEast();
          let new_sw = new google.maps.LatLng(sw.lat() - 0.0004, sw.lng() - 0.0006);
          let new_ne = new google.maps.LatLng(ne.lat() + 0.0004, ne.lng() + 0.0006);
          let new_bounds = new google.maps.LatLngBounds(new_sw, new_ne);
          map.fitBounds(new_bounds);
        });

        window.grouped_markers.push(new_group_marker)
      }
    }

    setTimeout(function() {
      for (var i = 0; i < expired_group_markers.length; i++) {
        var ex_marker = expired_group_markers[i];
        ex_marker.map = null
      }
    }, 50);
  }
});
