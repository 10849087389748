document.addEventListener('turbolinks:load', function() {
  var searchTimeout;
  var searchValue = ''
  $(document).on("keyup", "input[data-search-path]", function(e) {
    var box = $(this)
    if (box.val() !== searchValue) {
      box.closest('div.container').next('div.container').find('.list-group-loading').removeClass('d-none');
      box.closest('div.container').next('div.container').find('.list-group-none').addClass('d-none');
      box.closest('div.container').next('div.container').find('.list-group-object').addClass('d-none');
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(function() {
        checksearch(box)
      }, 500);
      searchValue = box.val()
    }
  });

  function checksearch(box){
    var path = box.data('search-path');
    var query = box.val();
    $.ajax(path, {
      method: "GET",
      dataType: 'script',
      data: {
        query: query,
        authenticity_token: $('[name="csrf-token"]')[0].content,
      }
    });
  }

});